<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-data-table :headers="headers"
                      :loading="loader"
                      :items="getFormattedNFCReports"
                      disable-pagination
                      disable-sort
                      hide-default-footer
                      class="elevation-1">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header, on }">
                <SortableHeader :header-item="header"
                                v-on="on"
                                @sort="sort($event)"
                                :key="i"></SortableHeader>
            </template>
            <template v-slot:item.actions="{ item }">
                <HintingIcon class-name="mr-2"
                             :icon="ICONS.XLSX"
                             data-test="showDetailsBtn"
                             @click="downloadXLSX(item.id)"
                             :tooltip="$t('labels.download_xls')"></HintingIcon>

                <HintingIcon class-name="mr-2"
                             :icon="ICONS.PDF"
                             @click="downloadPDF(item.id)"
                             data-test="editReportBtn"
                             :tooltip="$t('labels.download_pdf')"></HintingIcon>

            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ICONS } from '@/constants/icons';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import HintingIcon from '@/components/widgets/HintingIcon';
import { downloadBase64Document } from '@/helpers/files';
import reportsApi from '@/utils/api/reports';

export default {
    name: 'NfcReportTable',
    components: { SortableHeader, HintingIcon },
    props: {
        loader: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        ICONS,
        sortable: {
            created_at: 'desc',
            name: 'asc'
        }

    }),
    computed: {
        ...mapState('reports', [
            'nfcReports'
        ]),
        ...mapGetters('reports', [
            'getFormattedNFCReports'
        ]),
        headers () {
            return [
                {
                    text: 'Lp',
                    value: 'seq',
                    sortable: false
                },
                {
                    text: this.$t('labels.date'),
                    value: 'formatted_date',
                    sortable: true
                },
                {
                    text: this.$t('labels.location'),
                    value: 'name',
                    sortable: true
                },
                {
                    text: this.$t('labels.actions'),
                    value: 'actions',
                    width: '120px',
                    sortable: false
                }
            ];
        }
    },
    methods: {
        sort (event) {
            let val = event;
            if (val === 'formatted_date') {
                val = 'created_at';
            }
            switch (this.sortable[val]) {
            case null:
                this.sortable[val] = 'desc';
                break;
            case 'asc':
                this.sortable[val] = 'desc';
                break;
            case 'desc':
                this.sortable[val] = 'asc';
                break;
            }
            this.$emit('sort', {
                name: val,
                value: this.sortable[val]
            });
        },
        downloadPDF (id) {
            const params = { 'extra_fields[nfc_reports]': 'pdf_file' };
            return reportsApi.getSingleNFCReport(id, params).then(({ data }) => {
                const file = data.data.attributes?.pdf_file;
                const name = data.data.attributes?.name;
                downloadBase64Document(file, name, 'application/pdf');
            });
        },
        downloadXLSX (id) {
            const params = { 'extra_fields[nfc_reports]': 'xlsx_file' };
            return reportsApi.getSingleNFCReport(id, params).then(({ data }) => {
                const file = data.data.attributes?.xlsx_file;
                const name = data.data.attributes?.name;
                downloadBase64Document(file, name, 'application/vnd.ms-excel');
            });
        }
    }
};
</script>

<style scoped>

</style>
