<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.nfc_reports') }}</h1>
        <FiltersBar :with-search="false"
                    @reset="setDateFilter"
                    ref="filtersBar"></FiltersBar>

        <NfcReportTable @sort="sortingColumn = $event"
                        :loader="loading"
                        @get-file="getFile($event, filesParams)"></NfcReportTable>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash.debounce';
import { FILTERS } from '@/constants/filtersModelNames';
import { PARSE_DATE, CONVERT_TO_UTC } from '@/helpers/dates';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import filtering from '@/mixins/filtering';
import DatePickerFilter from '@/models/filters/datePickerFilter';
import NfcReportTable from '@/components/reports/NfcReportTable';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';

export default {
    name: 'NfcReport',
    mixins: [filtering, debouncedCustomers, debouncedSites, debouncedLocations],
    components: { FiltersBar, NfcReportTable },
    data: () => ({
        debouncedFetchNfcReport: null,
        loading: false,
        sortingColumn: null
    }),
    computed: {
        currentDate () {
            return PARSE_DATE(new Date());
        },
        filtersConfig () {
            return [
                new DatePickerFilter({
                    model: FILTERS.CREATED_AT,
                    label: this.$t('labels.date_from')
                })
            ];
        },
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        date () {
            return this.filters[FILTERS.CREATED_AT] || this.currentDate;
        },
        utcDateStart () {
            return CONVERT_TO_UTC(`${this.date} 00:00:00`);
        },
        utcDateEnd () {
            return CONVERT_TO_UTC(`${this.date} 23:59:59`);
        },
        filesParams () {
            return {
                kind: 'nfc_report',
                year: this.year,
                month: this.month
            };
        }
    },
    watch: {
        sortingColumn: {
            handler () {
                this.fetchNfcReport();
            },
            deep: true
        }
    },
    methods: {
        fetchNfcReport () {
            this.loading = true;
            const params = {
                '[filter]created_at[gte]': this.utcDateStart
                // '[filter]created_at[lte]': this.utcDateEnd
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            return this.$store.dispatch('reports/getNFCReport', params).finally(() => {
                this.loading = false;
            });
        },
        setDateFilter () {
            this.$refs.filtersBar.setSpecifyFilter(FILTERS.CREATED_AT, this.date.toString());
        }
    },
    created () {
        this.debouncedFetchNfcReport = debounce(this.fetchNfcReport, 1000);
    },
    mounted () {
        this.setFiltersWatcher(this.debouncedFetchNfcReport);
        this.setDateFilter();
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.CUSTOM_REPORTS)) {
                vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        next();
    }
};
</script>

<style scoped>

</style>
